import { ColleaguesService } from "../../services/colleagues.service";

const state = {};

const actions = {
  async GetAll({}, { state, fullName, fromDate, toDate }) {
    let result = await ColleaguesService.AllRequestColleagues(state, fullName, fromDate, toDate);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  advicerColleaguesState: state,
  actions,
  mutations,
};
 
